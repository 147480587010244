import React from 'react';
import { Center, Flex, Box, Text } from '@mantine/core';
import { CustomerRecord } from '../../../../../Models/CustomerInfo';
import { NormalText } from '../../../../../Styles/Texts';
import Record from './Record';

export default function RecordWrapper(props: {
  records?: CustomerRecord[];
  handleClick: (record: CustomerRecord) => void;
  handleDelete: (recordId: string) => void;
}) {
  const { records, handleClick, handleDelete } = props;

  return (
    <>
      {(!records || records?.length === 0) && (
        <div>
          <Center>
            <NormalText>ยังไม่มีเอกสาร</NormalText>
          </Center>
        </div>
      )}
      <Flex p="16px 16px 16px 0px">
        <Box w="45%">
          <Text fw="500" c="#26262C">
            ชื่อเอกสาร
          </Text>
        </Box>
        <Box w="20%">
          <Text fw="500" c="#26262C">
            วันที่สร้าง
          </Text>
        </Box>
        <Box w="20%">
          <Text fw="500" c="#26262C">
            วันหมดอายุ
          </Text>
        </Box>
        <Box w="10%">
          <Text fw="500" c="#26262C">
            สถานะ
          </Text>
        </Box>
        <Box w="5%"></Box>
      </Flex>
      {records?.map((record) => {
        return <Record key={record.recordId} record={record} handleClick={handleClick} handleDelete={handleDelete} />;
      })}
    </>
  );
}
