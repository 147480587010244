import { Card, Flex, Group, Popover, ActionIcon, Stack, Button, Image, Text, Box, Overlay } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { StatusBadge } from '../../../../../Widgets/StatusBadge';
import { CustomerRecord } from '../../../../../Models/CustomerInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store';
import { useDisclosure } from '@mantine/hooks';

export default function Record({
  record,
  handleClick,
  handleDelete,
}: {
  record: CustomerRecord;
  handleClick: (record: CustomerRecord) => void;
  handleDelete: (recordId: string) => void;
}) {
  const records = useSelector((state: RootState) => state.myRecordsSlice.myRecords);
  const fullRecordDetail = records.find((r: any) => r.recordId === record.recordId);
  const [deleteDialogOpened, deleteDialogAction] = useDisclosure();

  const isEnable = fullRecordDetail?.status === 'Expired' ? false : true;

  return (
    <Card
      style={{ border: '1px #ccc solid', marginBottom: '1em', cursor: isEnable ? 'pointer' : 'default' }}
      onClick={() => isEnable && handleClick(record)}
    >
      {!isEnable && <Overlay bg="#f9f9fa" opacity="0.5" />}
      <Flex>
        <Group w="45%">
          <Image src="/assets/library_books.svg" />
          <Text size="md" fw="500">
            {record.name}
          </Text>
        </Group>
        <Group w="20%">
          <Text fz="14px" fw="300">
            {dayjs(record.dateCreated).format('DD MMM YYYY')}
          </Text>
        </Group>
        <Group w="20%">
          <Text fz="14px" fw="300">
            {fullRecordDetail?.expiredDate ? dayjs(fullRecordDetail.expiredDate).format('DD MMM YYYY') : '-'}
          </Text>
        </Group>
        <Group w="10%">
          <StatusBadge status={fullRecordDetail?.status ?? 'Unknown'} />
        </Group>
        <Group w="5%">
          <Popover opened={deleteDialogOpened} withArrow closeOnEscape closeOnClickOutside>
            <Popover.Target>
              <ActionIcon
                variant="white"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteDialogAction.open();
                }}
                size="lg"
                style={{ zIndex: 200 }}
              >
                <IconTrash style={{ margin: 'auto' }} />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack gap={10}>
                คุณต้องการลบข้อมูลบันทึกนี้หรือไม่?
                <Group justify="right">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(record.recordId);
                    }}
                  >
                    ลบ
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteDialogAction.close();
                    }}
                    variant="outline"
                  >
                    ไม่ลบ
                  </Button>
                </Group>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        </Group>
      </Flex>
    </Card>
  );
}
