import { Space } from '@mantine/core';
import { PageWrapper } from '../../../../Views/PageWrapper';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../Store';
import { loadActiveCustomer } from '../../../../Features/CustomerSlice';
import { listRecords } from '../../../../Features/MyRecordsSlice';
import { useTenantAlias } from '../../../../hooks/user';
import CustomerDocumentSection from './components/CustomerDocumentSection';
import CustomerInfoSection from './components/CustomerInfoSection';
import { AppBreadCrumbs } from '../../../../common/components/BreadCrumbs';
import React from 'react';

export function CustomerDataPage() {
  const { customerId } = useParams();
  const cust = useSelector((state: RootState) => state.customerSlice.activeCustomer);
  const user = useSelector((state: RootState) => state.userSlice.activeUser);
  const alias = useTenantAlias();
  const dispatch = useDispatch<AppDispatch>();

  const breadCrumbItems = [
    {
      label: 'ข้อมูลลูกค้า',
      href: `/${alias}/customer`,
    },
    {
      label: `${cust.firstname ?? '-'} ${cust.lastname ?? '-'}`,
      href: '',
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      if (customerId) await dispatch(loadActiveCustomer(customerId));
      await dispatch(listRecords({ clientId: user?.tenant.clientId!, customerId: customerId! }));
    };
    loadData();
  }, [customerId]);

  return (
    <PageWrapper>
      <AppBreadCrumbs items={breadCrumbItems} />
      {cust && (
        <>
          <CustomerInfoSection customer={cust} />
          <CustomerDocumentSection customer={cust} />
          <Space h="md" />
        </>
      )}
    </PageWrapper>
  );
}
