import { Paper, Space, Button, Grid, Divider, Title, Text } from '@mantine/core';
import { IconTrash, IconPencil } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mostRecentCustomerRecordSelector } from '../../../../../Features/MyRecordsSlice';
import { useTenantAlias } from '../../../../../hooks/user';
import { CustomerInfoStaged } from '../../../../../Models/CustomerInfo';
import { HeaderWithButtons } from '../../../../../Styles/HeaderWithButtons';
import { ScoreGrid } from '../../../../../Widgets/ScoreGrid';

const titleMapper: { [key: string]: string } = {
  mr: 'นาย',
  mrs: 'นาง',
  ms: 'นางสาว',
};

export default function CustomerInfoSection(props: { customer: CustomerInfoStaged }) {
  const { customer } = props;
  const mostRecentRecord = useSelector(mostRecentCustomerRecordSelector());

  const navigate = useNavigate();
  const alias = useTenantAlias();

  return (
    <Paper radius="lg" p="xl" my="lg" bg="white" style={{ boxShadow: '0px 8px 16px 0px rgba(222, 222, 227, 0.4)' }}>
      <Title order={2} c="#26262C" mb="16px">
        คะแนนลูกค้า
      </Title>
      <Paper bg="#F9F9FA" shadow="0" p="10px">
        <ScoreGrid record={mostRecentRecord} />
      </Paper>
      <Space h="24px" />
      <HeaderWithButtons>
        <Title order={2} c="#26262C">
          ข้อมูลส่วนตัว
        </Title>
        <Button
          leftSection={<IconPencil size="1.2em" />}
          variant="outline"
          onClick={() => {
            navigate(`/${alias}/customer/${customer._id}/edit`);
          }}
        >
          แก้ไขข้อมูลส่วนตัว
        </Button>
      </HeaderWithButtons>

      <Grid c="#26262C">
        <Grid.Col span={6}>
          <Text>คำนำหน้า</Text>
          <Text fw={500} c="#26262C">
            {customer.title ? titleMapper[customer.title] : '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}></Grid.Col>
        <Grid.Col span={6}>
          <Text>ชื่อ</Text>
          <Text fw={500} c="#26262C">
            {customer.firstname ?? '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text>นามสกุล</Text>
          <Text fw={500} c="#26262C">
            {customer.lastname ?? '-'}
          </Text>
        </Grid.Col>
      </Grid>
      <Space h="24px" />
      <Divider />

      <Space h="24px" />
      <HeaderWithButtons>ข้อมูลบัตรประจำตัวประชาชน</HeaderWithButtons>

      <Grid>
        <Grid.Col span={6}>
          <Text>หมายเลขบัตรประชาชน</Text>
          <Text fw={500} c="#26262C">
            {customer.cid ?? '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text>วันเกิดตามบัตรประชาชน</Text>
          <Text fw={500} c="#26262C">
            {customer.birthDate ? dayjs(customer.birthDate).format('DD/MM/YYYY') : '-'}
          </Text>
        </Grid.Col>
      </Grid>
      <Space h="24px" />
      <Divider />

      <Space h="24px" />
      <HeaderWithButtons>ข้อมูลติดต่อ</HeaderWithButtons>
      <Grid>
        <Grid.Col span={6}>
          <Text>เบอร์โทรศัพท์มือถือ</Text>
          <Text fw={500} c="#26262C">
            {customer.mobilePhone ?? '-'}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text>อีเมล์ติดต่อ</Text>
          <Text fw={500} c="#26262C">
            {customer.email ?? '-'}
          </Text>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
