import { Badge } from '@mantine/core';
import { RecordStatus } from '../Models/Record';

const statusColor: { [key: string]: string } = {
  Initial: 'var(--fill-clickable-default)',
  Draft: '#5E5E6E',
  Uploaded: 'var(--fill-warning-default)',
  Analyzing: 'var(--fill-warning-default)',
  Completed: 'var(--fill-success-default)',
  Done: 'var(--fill-success-default)',
  Error: 'var(--fill-error-default, rgb(221, 33, 12))',
  Loading: '#F2F2F2',
  Unknown: 'rgba(156, 156, 171, 1)',
  Expired: '#C8C8D0',
};

export function StatusBadge({ status }: { status: RecordStatus }) {
  const color = statusColor[status];
  return (
    <Badge color={color} style={{ borderRadius: '6px' }}>
      {status}
    </Badge>
  );
}
