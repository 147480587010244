import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Record } from '../Models/Record';
import axios from 'axios';
import { RootState } from '../Store';

// eslint-disable-next-line
const _sampleRecords = [
  {
    id: '001',
    name: 'RattanaB_SCB_Statement_Jan_Jun_2023',
    dateCreated: new Date(),
    status: 'Draft',
    bankStatementList: [],
  },
  {
    id: '002',
    name: 'RattanaB_SCB_Statement_Jan_Jun_2023',
    dateCreated: new Date(),
    status: 'Processing',
    bankStatementList: [],
  },
  {
    id: '003',
    name: 'RattanaB_SCB_Statement_Jan_Jun_2023',
    dateCreated: new Date(),
    status: 'Action Required',
    bankStatementList: [],
  },
  {
    id: '004',
    name: 'RattanaB_SCB_Statement_Jan_Jun_2023',
    dateCreated: new Date(),
    status: 'Done',
    bankStatementList: [],
  },
];

const _findRecord = (state: any, recordId: string) => state.myRecords.find((rec: Record) => rec.recordId === recordId);
const _getUser = (thunkApi: any) => (thunkApi.getState() as RootState).userSlice.activeUser!;

const listRecords = createAsyncThunk(
  'myRecord/list',
  async (params: { clientId?: string; customerId?: string; list?: string[] }, thunkApi: any) => {
    const user = _getUser(thunkApi);

    if (params.list != null && params.list.length === 0) {
      // no refresh on empty list
      return { data: { data: [] } };
    }

    const _listQuery = params.list != null && params.list.length > 0 ? `?list=${params.list.join(',')}` : null;
    const _custIdQuery = params.customerId != null ? `?customerId=${params.customerId}` : null;
    return axios.get<{ data: Record[] }>(
      `/api/internal/record/${params.clientId ?? user.tenant.clientId}${_listQuery ?? _custIdQuery ?? ''}`
    );
  }
);

const createRecord = createAsyncThunk(
  'myRecord/create',
  async (params: { recordName: string; customerId: string }, thunkApi: any) => {
    const user = _getUser(thunkApi);
    return axios.post<{ data: Record }>(`/api/internal/record/${user.tenant.clientId}`, {
      recordName: params.recordName,
      userId: user.userId,
      clientId: user.tenant.clientId,
      customerId: params.customerId,
    });
  }
);

const submitRecord = createAsyncThunk('myRecord/submit', async (record: Record, thunkApi: any) => {
  return axios.post<{ data: any }>(`/api/internal/record/${record.clientId}/${record.recordId}/submit`, {});
});

const refreshRecord = createAsyncThunk('myRecord/refresh', async (record: Record) => {
  return await axios.get<{ data: Record }>(`/api/internal/record/${record.clientId}/${record.recordId}`);
});

const renameRecord = createAsyncThunk(
  `myRecord/rename`,
  async (params: { recordId: string; clientId: string; newName: string | undefined; customerId: string }) => {
    return axios.post(`/api/internal/record/${params.clientId}/${params.recordId}/rename`, {
      newName: params.newName,
      customerId: params.customerId,
    });
  }
);

const deleteRecord = createAsyncThunk(`myRecord/delete`, async (params: { clientId: string; recordId: string }) => {
  return axios.delete(`/api/internal/record/${params.clientId}/${params.recordId}`);
});

const myRecordSlice = createSlice({
  name: 'myRecord',
  initialState: {
    myRecords: [],
    activeRecord: null,
    listLoading: false,
    errorMessage: null,
  } as any,
  reducers: {
    addMyRecord(state: any, action: { payload: { recordName: string; clientId: string; userId: string } }) {
      const newRecord: Record = {
        recordId: 'temporary_id',
        path: '',
        userId: action.payload.userId,
        clientId: action.payload.clientId,
        name: action.payload.recordName,
        dateCreated: new Date().toISOString(), // Will be overriden by user
        status: 'Draft',
        bankStatementList: [],
      };

      myRecordSlice.caseReducers.setActiveRecord(state, { payload: newRecord });
      state.myRecords.push(newRecord);
    },

    setActiveRecord(state: any, action: { payload: Record }) {
      state.activeRecord = action.payload;
    },

    resetActiveRecordErrorState(state: any) {
      state.activeRecord.status = 'Draft';
      state.activeRecord.statusMessage = null;
    },

    clearActiveRecord(state: any) {
      state.activeRecord = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRecord.fulfilled, (state, action) => {
      state.activeRecord.status = 'Draft';
      state.activeRecord.recordId = action.payload.data.data.recordId;
      state.myRecords = state.myRecords.filter((rec: Record) => rec.recordId !== 'temporary_id');
      state.myRecords.push(state.activeRecord);
    });

    builder.addCase(submitRecord.pending, (state, action) => {
      const record = state.myRecords.find((rec: Record) => rec.recordId === action.meta.arg.recordId);
      record.status = 'Uploaded';
      state.activeRecord = record;
    });

    builder.addCase(submitRecord.fulfilled, (state, action) => {
      const record = state.myRecords.find((rec: Record) => rec.recordId === action.meta.arg.recordId);
      if (record == null) {
        throw Error(`Record not found: ${action.meta.arg.recordId}`);
      } else {
        record.status = 'Analyzing';
        state.activeRecord = record;
      }
    });

    builder.addCase(submitRecord.rejected, (state, action) => {
      const record = state.myRecords.find((rec: Record) => rec.recordId === action.meta.arg.recordId);
      if (record != null) {
        record.status = 'Error';
      }
    });

    builder.addCase(listRecords.pending, (state, action) => {
      state.listLoading = true;
    });

    builder.addCase(listRecords.fulfilled, (state, action) => {
      state.listLoading = false;
      const response = action.payload.data?.data || [];
      state.myRecords = response
        .map(_responseToRecordMapper)
        .filter((r) => r != null)
        .sort((a: Record | null, b: Record | null) => ((a?.dateCreated ?? '') > (b?.dateCreated ?? '') ? 1 : -1));
    });

    builder.addCase(refreshRecord.rejected, (state, action) => {
      throw Error('Error refreshing record ' + action.meta.arg.recordId);
    });

    builder.addCase(refreshRecord.fulfilled, (state, action) => {
      const record = state.myRecords.find((rec: Record) => rec.recordId === action.meta.arg.recordId);
      if (record == null) {
        // console.log(`Record ${action.meta.arg.recordId} not found.`);
      } else {
        state.myRecords = state.myRecords.map((rec: Record) => {
          if (rec.recordId === action.meta.arg.recordId) {
            return _responseToRecordMapper(action.payload.data?.data);
          } else {
            return rec;
          }
        });

        if (state.activeRecord != null) {
          myRecordSlice.caseReducers.setActiveRecord(state, {
            payload: _findRecord(state, state.activeRecord.recordId),
          });
        }
      }
    });

    builder.addCase(renameRecord.fulfilled, (state, action) => {
      state.activeRecord.name = action.meta.arg.newName;
    });

    builder.addCase(deleteRecord.fulfilled, (state, action) => {
      state.myRecords = state.myRecords.filter((rec: Record) => rec.recordId !== action.meta.arg.recordId);
    });
  },
});

function _responseToRecordMapper(apires: any): Record | null {
  if (apires == null) return null;

  return {
    recordId: apires.recordId,
    clientId: apires.clientId,
    userId: apires.userId,
    name: apires.recordName,
    dateCreated: apires.dateCreated,
    expiredDate: apires.expiredDate,
    status: apires.status,
    bankStatementList: apires.statements,
    result: apires.result,
    statusMessage: apires.statusMessage,
    error: apires.error,
    path: `/${apires.clientId}/${apires.recordId}/record.json`,
  };
}

export const mostRecentCustomerRecordSelector = (recordId?: string) => (state: RootState) => {
  const customerRecords = state.myRecordsSlice.myRecords.filter((rec: Record) => rec.status !== 'Expired');
  return customerRecords.sort((a: Record, b: Record) => b.dateCreated.localeCompare(a.dateCreated))[0];
};

export default myRecordSlice.reducer;
export const { addMyRecord, setActiveRecord, clearActiveRecord, resetActiveRecordErrorState } = myRecordSlice.actions;
export { createRecord, submitRecord, listRecords, refreshRecord, renameRecord, deleteRecord };
